import * as React from 'react';
import HeroSection from '@/components/Blog/HeroSection';
import Layout from '@/components/Layout';
import SEO from '@/components/SEO';
import { PageWrapper } from '@/styles/pages/blog';
import { graphql, useStaticQuery } from 'gatsby';
import { useMemo } from 'react';
import { IGatsbyImageData } from 'gatsby-plugin-image';
import RecentPosts from '@/components/Blog/RecentPosts';
import Categorias from '@/components/Blog/Categorias';
import { PageContainer } from '@/styles/pages';

interface IImage {
  id: string;
  imageFile: {
    childImageSharp: {
      gatsbyImageData: IGatsbyImageData;
    };
  };
}

interface ICategories {
  id: string;
  post_categories_id: {
    id: string;
    name: string;
    slug: string;
  };
}

export interface IPost {
  banner: any;
  id: string;
  categories: ICategories[];
  date: string;
  excerpt: string;
  highlight: boolean;
  preview: IImage;
  slug: string;
  title: string;
  banner?: IImage;
}

interface IBlogData {
  allOtherPosts: IPost[];
  latestPost: IPost;
  latest4Posts: IPost[];
}

const BlogPage = (): JSX.Element => {
  const { directus } = useStaticQuery(query);

  const blogData = useMemo((): IBlogData => {
    let sortedPosts = directus.posts.sort(compareByDate) as IPost[];

    const latestPost = sortedPosts[0] as IPost;
    const latest4Posts = sortedPosts.slice(1, 5) as IPost[];

    sortedPosts = sortedPosts.slice(5);

    return { allOtherPosts: sortedPosts, latestPost, latest4Posts };
  }, [directus.posts]);
  const categories = useMemo(
    () => directus.post_categories,
    [directus.post_categories]
  );

  return (
    <>
      <SEO title="Blog" description="Conheça o Blog da Rodomaxlog" />
      <Layout>
        <PageWrapper>
          <PageContainer>
            <HeroSection post={blogData.latestPost} />
            <RecentPosts posts={blogData.latest4Posts} />
            {directus.posts.length > 5 && (
              <Categorias
                categories={categories}
                posts={blogData.allOtherPosts}
              />
            )}
          </PageContainer>
        </PageWrapper>
      </Layout>
    </>
  );
};

export default BlogPage;

const query = graphql`
  query {
    directus {
      posts(filter: { status: { _in: "published" } }) {
        id
        title
        slug
        date
        excerpt
        highlight

        categories {
          id
          post_categories_id {
            name
            slug
          }
        }
        preview {
          id
          imageFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }

        banner {
          id
          imageFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }

      post_categories {
        id
        name
        slug
      }
    }
  }
`;

function compareByDate(a: any, b: any): number {
  if (a.date < b.date) {
    return 1;
  }
  if (a.date > b.date) {
    return -1;
  }
  return 0;
}
