import * as React from 'react';
import { convertDate } from '@/utils/convertDate';
import { Container, Post } from './styles';
import { GatsbyImage } from 'gatsby-plugin-image';
import { IPost } from '@/pages/blog';

interface Props {
  posts: IPost[];
}

const RecentPosts: React.FC<Props> = ({ posts }) => {
  return (
    <Container>
      {posts.map((post, index) => {
        const publishedDate = convertDate(post.date);

        return (
          <Post key={post.id + index}>
            <a href={'/blog/' + post.slug}>
              {' '}
              <GatsbyImage
                image={post.preview.imageFile.childImageSharp.gatsbyImageData}
                alt="Imagem de capa blog"
              />
            </a>

            <p>{publishedDate}</p>
            <a href={'/blog/' + post.slug} className="title">
              {post.title}{' '}
            </a>
          </Post>
        );
      })}
    </Container>
  );
};

export default RecentPosts;
