import { colors } from '@/styles/GlobalStyle';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  max-width: 1340px;

  margin-top: 48px;
  margin-bottom: 48px;

  display: flex;
  justify-content: space-between;

  .gatsby-image-wrapper {
    height: 190px;
    width: 315px;
  }

  @media (max-width: 1376px) {
    padding: 0 16px;
  }
`;

export const Left = styled.div`
  width: 100%;
  max-width: 832px;

  margin-top: 16px;

  display: flex;
  flex-direction: column;
  gap: 16px;

  .select-container {
    width: max-content;
    border: 1px solid ${colors.blue500};
    padding: 5px 10px;
    border-radius: 4px;
  }
  select {
    background: none;
    border: none;
    outline: none;
    color: ${colors.blue500};
    font-size: 20px;
    font-weight: 500;

    padding-right: 5px;
  }

  @media (max-width: 700px) {
    gap: 32px;
    align-items: center;
  }
`;

export const Post = styled.div`
  width: 100%;

  display: flex;
  gap: 40px;

  a:not(.title) {
    display: flex;

    img {
      transition: 0.15s linear;
    }
    :hover {
      img {
        transform: scale(1.06);
      }
    }
  }

  a.title {
    font-size: 20px;
    line-height: 24px;
    font-weight: 700;

    margin-top: 7px;

    transition: 0.15s linear;

    :hover {
      color: ${colors.blue500};
    }
  }

  > span,
  img {
    width: 312px !important;
    height: 176px !important;

    max-width: initial !important;
    overflow: visible !important;
    object-fit: cover;
  }

  div {
    span {
      color: ${colors.gray500};
      font-size: 15px;
      line-height: 20px;
      display: block;
    }

    p {
      font-size: 15px;
      line-height: 20px;
      margin-top: 5px;
    }

    a:not(.title) {
      display: flex;

      img {
        transition: 0.15s linear;
      }
      :hover {
        img {
          transform: scale(1.06);
        }
      }
    }

    a.title {
      font-size: 20px;
      line-height: 24px;
      font-weight: 700;

      margin-top: 7px;

      transition: 0.15s linear;

      :hover {
        color: ${colors.blue500};
      }
    }
  }

  @media (max-width: 700px) {
    flex-direction: column;
    gap: 5px;

    max-width: 312px;

    div {
      margin-top: 16px;
    }
  }
`;
