import * as React from 'react';
import { convertDate } from '@/utils/convertDate';
import { GatsbyImage } from 'gatsby-plugin-image';
import { useCallback, useEffect, useState } from 'react';
import { Container, Left, Post } from './styles';
import { IPost } from '@/pages/blog';

interface ICategores {
  id: string;
  name: string;
  slug: string;
}

interface Props {
  categories: ICategores[];
  posts: IPost[];
}

const Categorias: React.FC<Props> = ({ categories, posts }) => {
  const [selectedCategory, setSelectedCategory] = useState('');

  //TODO: garantir que só vai aparecer categories que tem pelo menos 1 post

  const filterByCategory = useCallback(() => {
    if (selectedCategory === '') {
      return posts;
    }

    return posts.filter((post) =>
      post.categories.some(
        (category) => category.post_categories_id.name === selectedCategory
      )
    );
  }, [selectedCategory, posts]);

  const [filteredPosts, setFilteredPosts] = useState(filterByCategory());

  useEffect(() => {
    setFilteredPosts(filterByCategory());
  }, [filterByCategory]);

  return (
    <Container>
      <Left>
        <div className="select-container">
          <select onChange={(e) => setSelectedCategory(e.target.value)}>
            <option value="">Últimas</option>
            {categories.map((category) => (
              <option key={category.id} value={category.name}>
                {category.name}
              </option>
            ))}
          </select>
        </div>
        {filteredPosts.slice(0, 5).map((post) => {
          const publishedDate = convertDate(post.date);

          return (
            <Post key={post.id}>
              <a href={`/blog/${post.slug}`}>
                <GatsbyImage
                  image={post.preview.imageFile.childImageSharp.gatsbyImageData}
                  alt="Imagem de capa blog"
                />
              </a>

              <div>
                <span>{publishedDate}</span>
                <a className="title" href={'/blog/' + post.slug}>
                  {post.title}
                </a>
                <p>{post.excerpt}</p>
              </div>
            </Post>
          );
        })}
      </Left>
    </Container>
  );
};

export default Categorias;
