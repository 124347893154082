import * as React from 'react';
import { Container, Overlay } from './styles';
import { MdKeyboardArrowRight } from 'react-icons/md';
import { GatsbyImage } from 'gatsby-plugin-image';
import { IPost } from '@/pages/blog';
import moment from 'moment';

interface Props {
  post: IPost;
}

const HeroSection: React.FC<Props> = ({ post }) => {
  const dateParsed = moment(new Date(post.date), 'YYYY-MM-MM').format(
    'DD/MM/YYYY'
  );

  return (
    <Container>
      <GatsbyImage
        image={post?.preview?.imageFile?.childImageSharp?.gatsbyImageData}
        alt="Imagem de capa blog"
        objectFit="cover"
        objectPosition="100% 100%"
      />

      <Overlay>
        <p>{dateParsed}</p>
        <h1>{post.title}</h1>
        <a href={`/blog/${post.slug}`}>
          Leia mais <MdKeyboardArrowRight size={20} />
        </a>
      </Overlay>
    </Container>
  );
};

export default HeroSection;
