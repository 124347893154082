import { colors } from '@/styles/GlobalStyle';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  margin-top: 48px;

  display: grid;
  gap: 32px;
  grid-template-columns: repeat(auto-fit, minmax(120px, 312px));
  justify-content: center;

  @media (max-width: 1376px) {
    padding: 0 16px;
  }

  @media (max-width: 850px) {
    margin-top: 24px;
  }
`;

export const Post = styled.div`
  width: 100%;
  max-width: 312px;

  display: flex;
  flex-direction: column;

  > span {
    height: 176px;
  }

  p {
    color: ${colors.gray500};
    font-size: 15px;
    line-height: 20px;
    margin-top: 16px;
  }

  a:not(.title) {
    display: flex;

    img {
      transition: 0.15s linear;
    }
    :hover {
      img {
        transform: scale(1.06);
      }
    }
  }

  a.title {
    font-size: 20px;
    line-height: 24px;
    font-weight: 700;

    transition: 0.15s linear;

    :hover {
      color: ${colors.blue500}; //todo color
    }
  }

  .gatsby-image-wrapper {
    height: 190px;
    width: 315px;
  }

  @media (max-width: 750px) {
    h3 {
      margin-top: 11px;
    }
  }
`;
