import { colors } from '@/styles/GlobalStyle';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  max-width: 1342px;

  display: flex;

  position: relative;

  min-height: 296px;
  height: 532px;

  .gatsby-image-wrapper {
    width: 1342px;
  }
`;

export const Overlay = styled.div`
  width: 100%;
  position: absolute;

  padding-left: 80px;
  padding-bottom: 67px;

  bottom: 0;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), black);

  p {
    font-size: 16px;
    color: white;
    line-height: 20px;
  }

  h1 {
    margin-top: 12px;
    font-size: 48px;
    line-height: 48px;
    color: white;
    max-width: 617px;
  }

  a {
    color: ${colors.blue100}; //todo color
    font-weight: 700;
    font-size: 15px;
    line-height: 20px;

    display: flex;
    align-items: center;
    gap: 3px;

    transition: 0.15s linear;

    max-width: fit-content;

    margin-top: 10px;

    span {
      transition: 0.15s linear;
      display: flex;
      color: ${colors.blue100}; //todo color
    }

    :hover {
      box-shadow: 0px 1px 0px ${colors.blue100}; //todo color

      span {
        transform: translateX(5px);
      }
    }
  }

  @media (max-width: 875px) {
    padding-left: 32px;
    padding-bottom: 22px;

    h1 {
      font-size: 20px;
      line-height: 24px;
      max-width: 312px;
    }
  }

  @media (max-width: 420px) {
    padding-left: 16px;
    padding-right: 16px;
  }
`;
